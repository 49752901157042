// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
//require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
// require('jquery');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '../src/common.scss';
import 'bootstrap';
//import '@fortawesome/fontawesome-free/js/all';
import '../src/application.scss';
import '../src/style.scss';

// Leaflet用のcss読み込み
import 'leaflet/dist/leaflet.css';
import '../src/l_map.scss';

const token = document.getElementsByName('csrf-token')[0].content;

window.sleep = function(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

document.addEventListener('load', (event)=>{
  if(typeof(gtag) == 'function'){
    gtag('config', 'Your Google Analytics Tracking Code', {
      'page_title' : event.target.title,
      'page_path': event.data.url.replace(window.location.protocol + "//" + window.location.hostname, "")
    });
  }
});

window.cacheClear = function(){
  caches.delete('mypage_index');
  caches.delete('online_index');
  caches.delete('temporary_cache');
  console.log('cache data is deleted!');
};

window.insertErrMes = function(json){
    
    if ( document.getElementsByClassName('alert')[0] ) {
        document.getElementsByClassName('alert')[0].remove();
    }
    
    var outputHtml = '<div class="alert alert-danger"><p>登録エラー</p><ul>';
    var insObj = document.getElementById('errmes');
    
    for (var key in json) {
        outputHtml += `<li>${key}${json[key][0]}</li>`;
    }
    
    outputHtml += '</ul></div>';
    insObj.insertAdjacentHTML('beforebegin',outputHtml);
    document.getElementsByName('commit')[0].removeAttribute('disabled');
    alert('入力データが不足しています。');
    
};

window.imageContainFormFetch = async function(myForm, canvases, url, method) {
  // formページ側にcanvasが必要（formと同じ親要素を持つ複数のcanvas）、form.jsでsubmitボタンに本処理を登録する必要がある
  
  let formData = new FormData(myForm);
  
  var promises = Array.prototype.map.call(canvases, function(canvas) {
      return new Promise(function (resolve) {
        canvas.toBlob((blob)=>{resolve([canvas.getAttribute("name"),blob])}, 'image/jpg', 0.9);
      });
  });
  
  Promise.all(promises).then(name_and_blobs => {
    for (let name_and_blob of name_and_blobs) {
      if (name_and_blob[0] != null ){
        formData.delete(name_and_blob[0]);
        formData.append(name_and_blob[0], name_and_blob[1]);
      }
    }

    if(myForm.getAttribute("data-remote")=="true"){
      url = url + '.js';
    } else {
      let loading = document.getElementById('loading_container');
      loading.className = 'loading_container_show';
    }
    
    fetch(url,{
      method: method, 
      body: formData,
      mode: "same-origin", 
      credentials: "include",
      headers: {
        'X-CSRF-Token': token
        }
    })
    .catch(err => {
      alert('通信エラーにより登録が正しく行われませんでした。');
      loading.className = 'loading_container_hidden';
    })
    .then(response => {
      
      const responseBodyPromise = response.text();
      return responseBodyPromise.then(body => ({ body: body, responseOk: response.ok, url: response.url }));
    })
    .then(({ body, responseOk, url }) => {
      if (responseOk) {
        if(myForm.getAttribute("data-remote")=="true"){
          const response_js = Function(body)
          response_js();
        } else {
          const parser = new DOMParser();
          const doc = parser.parseFromString(body, 'text/html');

          if(doc.querySelector('.image_contain_form') && doc.querySelector('.image_contain_form').querySelector('.alert-danger')){
            // エラー時はメッセージだけ出す
            document.querySelector('.image_contain_form').insertAdjacentElement('afterbegin',doc.querySelector('.image_contain_form').querySelector('.alert-danger'));
            document.getElementById('loading_container').className = 'loading_container_hidden';
            window.scroll({top: 0,behavior: "smooth",});
          } else {
            // 正常時は移動
            location.href=url;
          }
        }
      } else {
        // 40x系、50x系エラー
        insertErrMes(body);
        document.getElementById('loading_container').className = 'loading_container_hidden';
      }
      
    });
  });

};

window.addEventListener("load", (event) => {
  console.log(document.querySelectorAll('.image_contain_form').length);
  if( document.querySelectorAll('.image_contain_form').length  != 0) {
    document.querySelectorAll('.image_contain_form').forEach((form)=>{
      form.querySelector('.image_contain_form_submit').addEventListener('click', (e)=>{
        e.preventDefault();
        window.imageContainFormFetch(form, form.querySelectorAll('.image_resize_canvas'), form.action, form.method);
      });
    });
  }
});


window.videoUpload = function(e) {
  const btn = e.target;
  const video_form_id = btn.getAttribute("video_form_id");
  
  const up_input = document.getElementById(`video_upload_input_${video_form_id}`);
  const up_file = up_input.files[0];
  
  if (up_input.value === "") {
    alert("ファイルが選択されていません。");
    return false;
  }
  const mesdiv = document.getElementById(`video_upload_finish_message_${video_form_id}`);
  
  const progress_container = document.getElementById(`progress_container_${video_form_id}`);
  const progress = document.getElementById(`video_up_progress_${video_form_id}`);
  const progress_size = Math.ceil((2000000/up_file.size)*100);
  if(up_file.size > 300000000){
    alert("このファイルは" + Math.round(up_file.size/1000000)  + "MBです。ファイルサイズが大きすぎます。");
    return false;
  }
  
  const url='/videos?filename=' + up_file.name + "&filetype=" + up_file.type;
  fetch(url,{
    method: 'POST', 
    mode: "same-origin", 
    credentials: "include",
    headers: {
      'X-CSRF-Token': token
      }
  }).then(response => {
    if(response.ok){
      return response.json();
    }
  }).then((data)=>{
    let formdata = new FormData();
    for (let key in data.fields) {
      formdata.append(key,data.fields[key]);
    }
    formdata.append("file",up_file);
    const headers = {
      "accept": "multipart/form-data"
    };
    
    // progressbar表示
    btn.classList.add("nonedisp");
    mesdiv.classList.add("nonedisp");
    progress_container.classList.remove("nonedisp");
    let w=1;
    let interval_id=setInterval(()=>{
      progress.style.width =w+"%";
      if(progress_size<30){
        w=w+progress_size;
      } else {
        w=w+10;
      }
      if(w>99){
        progress.style.width ="100%";
        clearInterval(interval_id);
        alert("動画アップロードに失敗しました。WiFi接続環境でアップロードしてみてください。");
      }
    },1000);
    
    // S3 に POST
    fetch(
      data.url,
      {
        method: 'POST',
        headers,
        body: formdata
      }
    )
    .then((response) => {
      if(response.ok){
        document.getElementById(`video_upload_video_radio_${video_form_id}`).value = data.id;
        document.getElementById(`video_upload_sub_modal_submit_btn_${video_form_id}`).classList.remove("nonedisp");
        mesdiv.classList.remove("nonedisp");
        btn.classList.remove("nonedisp");
        progress_container.classList.add("nonedisp");
        up_input.value="";
        if(interval_id){
          clearInterval(interval_id);
        }
      }
    })
    .catch(()=>{
      alert("動画アップロードに失敗しました。");
      btn.classList.remove("nonedisp");
      progress_container.classList.add("nonedisp");
      up_input.value="";
      if(interval_id){
        clearInterval(interval_id);
      }
    });
  });
};

window.approachInfomationFetch = function(myForm, canvas, url, method) {
  
  let loading = document.getElementById('loading_container');
  loading.className = 'loading_container_show';

  canvas.toBlob( (blob) => {
    var formData = new FormData(myForm);
    if ( blob ) {
      formData.append('approach_information[image_writed]', blob);
    }
    
    fetch(url,{
      method: method, 
      body: formData,
      mode: "same-origin", 
      credentials: "include",
      headers: {
        'X-CSRF-Token': token
        }
    })
    .catch(err => {
      alert('通信エラーにより登録が正しく行われませんでした。');
      loading.className = 'loading_container_hidden';
    })
    .then(response => {
      const responseBodyPromise = response.json();
      return responseBodyPromise.then(body => ({ body: body, responseOk: response.ok }));
    })
    .then(({ body, responseOk }) => {
      if (responseOk) {
        // 正常なリクエスト
        alert(body.notice);
        location.href=body.url;
      } else {
        // 40x系、50x系エラー
        insertErrMes(body);
        loading.className = 'loading_container_hidden';
      }
    });
  });
};

window.selectBoxRefresh = function(url, updateElement, method="") {

  fetch(url)
    .catch(err => {
      window.clearLoadingAndDispErrMes();
    })
    .then(response => {return response.json();})
    .then(json => {
        var optNum = updateElement.length;
        
        // リフレッシュか追加の処理
        if(method=="" || method=="add"){
          // リフレッシュはまず削除
          if(method==""){
            for ( var i = 0; i < optNum; i++ ){
                updateElement.remove(1);
            }
          }
          for (var key in json) {
              var opt = document.createElement("option");
              opt.value = json[key].id;
              opt.text = json[key].name;
              updateElement.add(opt);
          }
        } else if(method=="remove") {
          // removeは取得したjson分を消す
          for (var key in json) {
            for ( var j = 0; j < optNum; j++ ){
              if(updateElement[j].value == json[key].id){
                updateElement.remove(j);
                optNum--;
              }
            }
          }
        }
    });
};

window.delImageInput = function(node) {
  var parentNode = node.parentNode;
  var imgNode = parentNode.getElementsByTagName('img')[0];

  imgNode.src = '';
};

window.checkBoxRefresh = function(url, model, name, updateElement, opt, change_fnc="", add_atr_name="", add_atr_val="") {
  
  let loading = document.getElementById('loading_container');
  loading.className = 'loading_container_show';

  fetch(url)
    .catch(err => {
      window.clearLoadingAndDispErrMes();
    })
    .then(response => {return response.json();})
    .then(json => {
      let clrnum = Math.floor( Math.random() * 12);
      for (var key in json) {
        if(opt=='add'){
          let old_elm = document.getElementById(`${model}_${name}_${json[key].id}`);
          if(old_elm==undefined){
            var html;
            html = `<div class="checkbox_container checkbox_clr_${clrnum}"><label for="${model}_${name}_${json[key].id}">${json[key].name}</label>
                    <input type="checkbox" value="${json[key].id}" name="${model}[${name}][]" id="${model}_${name}_${json[key].id}">
                    </div>`;
            updateElement.insertAdjacentHTML('beforeEnd', html);
            
            if(change_fnc!=""){
              document.getElementById(`${model}_${name}_${json[key].id}`).setAttribute("onclick", `${change_fnc}("${model}_${name}_${json[key].id}")`);
            }
            
            if(add_atr_name!=""){
              document.getElementById(`${model}_${name}_${json[key].id}`).setAttribute(add_atr_name, add_atr_val);
            }
          }
        } else {
          document.getElementById(`${model}_${name}_${json[key].id}`).parentElement.remove();
        }
      }
      loading.className = 'loading_container_hidden';
    });
};

window.delImageInput = function(node) {
  var parentNode = node.parentNode;
  var imgNode = parentNode.getElementsByTagName('img')[0];

  imgNode.src = '';
};



window.changeImageInput =  async function(e, t, img_upload_size=800) {
  var parentNode = t.parentNode;
  var imgNode = parentNode.getElementsByTagName('img')[0];
  var url = window.URL.createObjectURL(e.target.files[0]);
  
  //image_resize_canvasがあれば設定
  imgNode.onload = function() {
    if( parentNode.getElementsByClassName("image_resize_canvas")[0] != null) {
      var canvas = parentNode.getElementsByClassName("image_resize_canvas")[0];
      var ctx = canvas.getContext('2d');
      var backgroundimgdata =　new Image();
      canvas.setAttribute("name", e.target.name);
      backgroundimgdata.onload = function() {
        let scale;
        if(backgroundimgdata.width > backgroundimgdata.height){
          if (backgroundimgdata.width < img_upload_size) {
            scale = 1;
          } else {
            scale = img_upload_size / backgroundimgdata.width;
          }
        } else {
          if (backgroundimgdata.height < img_upload_size) {
            scale = 1;
          } else {
            scale = img_upload_size / backgroundimgdata.height;
          }
        }
        var outputWidth = backgroundimgdata.width * scale;
        var outputHeight = backgroundimgdata.height * scale;
        canvas.width = outputWidth;
        canvas.height = outputHeight;
        ctx.drawImage(backgroundimgdata, 0, 0, backgroundimgdata.width, backgroundimgdata.height, 0, 0, outputWidth, outputHeight);
      };
      backgroundimgdata.src = imgNode.src;
    }
  };

  imgNode.src = url;
};

// ---------------------------
// 位置情報系の処理
// ---------------------------

window.nearObjectUrlOpen = function(url) {
  
  function callbackFunc(position){
    var current_lat_lng = new google.maps.LatLng( position.coords.latitude, position.coords.longitude);
    window.location.href = url + "?lat=" + current_lat_lng.lat() + "&lng=" + current_lat_lng.lng();
  }
  
  window.currentLocationFunc(callbackFunc, { maxWait: 10000, desiredAccuracy: 10 });
  
};

window.nearListGet = function(latinput, lnginput, getform, spinnerid="search_spinner"){
  
  function callbackFunc(position){
    var current_lat_lng = new google.maps.LatLng( position.coords.latitude, position.coords.longitude);
    latinput.value = current_lat_lng.lat();
    lnginput.value = current_lat_lng.lng();
    
    Rails.fire(getform, 'submit');
  }
  
  document.getElementById(spinnerid).classList.remove("nonedisp");
  window.currentLocationFunc(callbackFunc, { maxWait: 10000, desiredAccuracy: 10 });
    
};

if( document.getElementById("nav_location_search_link") != null) {
  document.getElementById('nav_location_search_link').addEventListener('click', (e)=>{
    e.preventDefault();
    window.nearObjectUrlOpen(e.currentTarget.href);
  });
}

window.chartContentsGet = function(ids){
  if(document.getElementById("ap_chart_get_form") != null){
    document.getElementById("ap_chart_get_form_ids").value=ids;
    Rails.fire(document.getElementById("ap_chart_get_form"),'submit');
  }
};

window.currentLocationFunc = function(callbackFunc, options){
  
  options = options || {};
  
  // 現在地を取得
  getAccurateCurrentPosition(
    // 取得成功した場合
    callbackFunc,
    // 取得失敗した場合
    function(error) {
      switch(error.code) {
        case 1: //PERMISSION_DENIED
          alert("位置情報の利用が許可されていません");
          break;
        case 2: //POSITION_UNAVAILABLE
          alert("現在位置が取得できませんでした");
          break;
        case 3: //TIMEOUT
          alert("タイムアウトになりました");
          break;
        default:
          alert("その他のエラー(エラーコード:"+error.code+")");
          break;
      }
    },
    options
  );
};

window.getDistance = function(from_lat, from_lng, to_lat, to_lng) {

   function radians(deg){
      return deg * Math.PI / 180;
   }
   
   if(!from_lat || !from_lng || !to_lat || !to_lng){
       return 6400;
   }

   return 6378.14 * Math.acos(Math.cos(radians(from_lat))* 
    Math.cos(radians(to_lat))*
    Math.cos(radians(to_lng)-radians(from_lng))+
    Math.sin(radians(from_lat))*
    Math.sin(radians(to_lat)));
};

function getAccurateCurrentPosition(geolocationSuccess, geolocationError, options) {
  // Copyright (C) 2013 Greg Wilson
  // Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
  // The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
  // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
  
  var location_search_spinner = document.getElementById("location_search_container");
  var lastCheckedPosition,
      locationEventCount = 0,
      watchID,
      timerID;

  options = options || {};

  var checkLocation = function (position) {
    lastCheckedPosition = position;
    locationEventCount = locationEventCount + 1;
    // We ignore the first event unless it's the only one received because some devices seem to send a cached
    // location even when maxaimumAge is set to zero

    if ((position.coords.accuracy <= options.desiredAccuracy) && (locationEventCount > 1)) {
      if( options.alert == true){
        alert(position.coords.accuracy);
      } else {
      }
      clearTimeout(timerID);
      navigator.geolocation.clearWatch(watchID);
      foundPosition(position);
    }
  };

  var stopTrying = function () {
    location_search_spinner.classList.replace("loading_container_show", "loading_container_hidden");
    navigator.geolocation.clearWatch(watchID);
    foundPosition(lastCheckedPosition);
  };

  var onError = function (error) {
    location_search_spinner.classList.replace("loading_container_show", "loading_container_hidden");
    clearTimeout(timerID);
    navigator.geolocation.clearWatch(watchID);
    geolocationError(error);
  };

  var foundPosition = function (position) {
    geolocationSuccess(position);
    location_search_spinner.classList.replace("loading_container_show", "loading_container_hidden");
    if(position==undefined){
      let alert_elm = document.createElement("div");
      alert_elm.classList.add("alert", "alert-info", "location_alert", "fadein");
      alert_elm.innerText="位置情報を取得できませんでした。\n何度か実行すると取得できる場合があります。";
      document.body.appendChild(alert_elm);
      setTimeout(()=>{alert_elm.remove()},4000);
    }
  };

  if (!options.maxWait)            options.maxWait = 10000; // Default 10 seconds
  if (!options.desiredAccuracy)    options.desiredAccuracy = 30; // Default 30 meters
  if (!options.timeout)            options.timeout = options.maxWait; // Default to maxWait
  if (!options.alert)              options.alert = false; // Default to false

  options.maximumAge = 0; // Force current locations only
  options.enableHighAccuracy = true; // Force high accuracy (otherwise, why are you using this function?)

  location_search_spinner.classList.replace("loading_container_hidden", "loading_container_show");
  timerID = setTimeout(stopTrying, options.maxWait); // Set a timeout that will abandon the location loop
  watchID = navigator.geolocation.watchPosition(checkLocation, onError, options);
}

window.clearLoadingAndDispErrMes = function(){
  let loading = document.getElementById('loading_container');
  loading.className = 'loading_container_hidden';
  alert("データの読み込みが正常に行われませんでした。");
};